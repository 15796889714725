<template>
    <div v-if="user" class="user" :class="{open: menuIsOpen}">
        <div class="user__header" role="button" tabindex="0" @click="toggleMenu()" @keydown.enter="toggleMenu">
            <div class="user__header__name">
                <div class="text-primary fw-bold">{{ user.name }}</div>
                <div>{{ user.roleLabel }}</div>
            </div>

            <span class="user__header__avatar">
                <UserIcon v-if="user" :user="user" class="avatar" />
            </span>
        </div>

        <div class="user__dropdown">
            <ul class="menu list-unstyled">
                <li>
                    <div class="text-muted"><small>Gebruikersnummer</small></div>
                    <ClipboardButton :text="user.code">{{ user?.code }}</ClipboardButton>
                </li>
                <li>
                    <RouterLink class="" :to="{name: Routes.ProfileView}" @click="closeMenu">
                        Profiel
                    </RouterLink>
                </li>
                <li>
                    <RouterLink v-if="user?.role === 'teacher'" class="" :to="{name: Routes.ObserverList}" @click="closeMenu">
                        Observatoren
                    </RouterLink>
                    <RouterLink v-if="user?.role === 'observer'" class="" :to="{name: Routes.TeacherList}" @click="closeMenu">
                        Docenten
                    </RouterLink>
                </li>
                <li class="logout">
                    <FetchButton class="" target="wp-json/api/logout" @response="onLogoutResponse">
                        Uitloggen
                    </FetchButton>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import useCurrentUser from '@/composeables/useCurrentUser';
import {Routes} from '@/router';
import {useRouter} from 'vue-router';
import FetchButton from '@/components/form/FetchButton.vue';
import UserIcon from '@/components/user/UserIcon.vue';
import ClipboardButton from '@/components/standalone/ClipboardButton.vue';
import {CurrentUser} from '@/models/CurrentUser';

export default defineComponent({
    name: 'UserMenu',
    components: {ClipboardButton, FetchButton, UserIcon},
    props: {
        user: {type: Object as PropType<CurrentUser>, default: undefined}
    },
    setup() {
        const router = useRouter();
        const menuIsOpen = ref(false);

        return {
            menuIsOpen,
            onLogoutResponse() {
                useCurrentUser().clear();
                router.push({name: Routes.Login});
            },
            toggleMenu() {
                menuIsOpen.value = !menuIsOpen.value;
            },
            closeMenu() {
                menuIsOpen.value = false;
            },
            Routes
        }
    }
})
</script>
