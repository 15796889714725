import {StatementGroup, StatementGroupSchema} from '@/models/StatementGroup';
import XhrResource from '@/helpers/XhrResource';
import {mapObject} from '@/helpers/objectHelper';
import {computed} from 'vue';

const resource = new XhrResource((data: Record<string, unknown>) => {
    return mapObject(data, (o) => StatementGroupSchema.parse(o));
});

export default () => ({
    load() {
        return resource.load(`wp-json/api/statement-groups/`);
    },
    clear: () => resource.clear(),
    statementGroups: resource.get(),
    sortedGroups: computed(() => {
        const statementGroups = resource.get().value ?? {};
        const sortedGroups: StatementGroup[] = [];

        Object.values(statementGroups).forEach((group) => {
            sortedGroups[group.order] = group;
        });

        return sortedGroups.filter(Boolean);
    })
});
