<template>
    <template v-if="observation">
        <div class="layout__wrapper">
            <div class="row__header">
                <div class="mb-2">
                    <RouterLink :to="{name: Routes.TeacherDetail, params: {teacherId: observation.teacherId}}">« Terug naar de observaties</RouterLink>
                </div>
                <h1 class="text-primary fw-bold mb-0">{{ observation.name }}</h1>
                <small>Observator: {{ observation.authorName }}</small>
            </div>

            <div class="row__tabs row--last mb-4">
                <ul class="nav__tabs">
                    <li class="nav__tabs__tab">
                        <RouterLink class="nav-link" :to="{name: Routes.ObservationGeneralInfo, params: {observationId}}">
                            Algemene Informatie
                        </RouterLink>
                    </li>
                    <li class="nav__tabs__tab">
                        <RouterLink v-if="user?.role ==='observer'" class="nav-link" :to="{name: Routes.ObservationForm, params: {observationId, groupId: Object.keys(statementGroups)[0] ?? 0}}">
                            Observatieformulier
                        </RouterLink>
                    </li>
                    <li class="nav__tabs__tab">
                        <RouterLink class="nav-link" :to="{name: Routes.ObservationResults, params: {observationId}}">
                            Resultaten
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </div>

        <RouterView/>
    </template>
    <ProgressSpinner v-else/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {Routes} from '@/router';
import useObservation from '@/composeables/useObservation';
import useStatementGroups from '@/composeables/useStatementGroups';
import useCurrentUser from '@/composeables/useCurrentUser';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import { fetchPost } from '@/helpers/fetchHelper';

export default defineComponent({
    name: 'ObservationView',
    components: {ProgressSpinner},
    props: {
        observationId: {type: Number, required: true},
        groupId: {type: Number, default: 0}
    },
    setup(props) {
        useStatementGroups().load();
        useObservation().load(props.observationId);

        return {
            user: useCurrentUser().user,
            observation: useObservation().observation,
            submit: (data: any) => {
                const formData = new FormData(data.target);
                fetchPost(`wp-json/api/set-user-request/`, formData)
            },
            statementGroups: useStatementGroups().statementGroups,
            Routes
        }
    }
})
</script>
