<template>
    <table class="table table-light table-striped table--padding-small table--fixed observation-score-table">
        <thead>
            <tr class="table-heading">
                <th v-if="user?.role === 'observer'" class="w-50px"/>

                <th class="w-50px">Item</th>

                <th>Domein</th>

                <th v-if="teacher?.gender === 'female'">De lerares</th>
                <th v-else>De leraar</th>

                <th v-for="i in 4" :key="i" :class="`th-${i}`" class="w-50px">{{ i }}</th>
            </tr>
        </thead>

        <tbody>
            <template v-if="statementGroups">
                <tr v-for="(statement, key) in statements" :key="key">
                    <td v-if="user?.role === 'observer'">
                        <div v-if="checkedBottom !== undefined && checkedTop !== undefined && key < checkedBottom && key > checkedTop" class="rasch-separator">|</div>

                        <label v-else>
                            <input type="checkbox" :data-statement-index="key" :checked="(key === checkedTop || key === checkedBottom)" :disabled="user?.id !== observation.authorId" @click="onSelect">
                        </label>
                    </td>

                    <td class="text--small">{{ statement.questionNumber }}</td>

                    <td class="text--small">{{ (statementGroups[statement.groupId]) ? statementGroups[statement.groupId].name : statement.groupId }}</td>

                    <td class="cell-question">{{ statement.question }}</td>

                    <td v-for="i in 4" :key="i" :class="(statement.score === i) ? `selected cell-${i}` : `cell-${i}`"/>
                </tr>
            </template>
        </tbody>
    </table>
    <ProgressSpinner v-if="!statementGroups"/>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue';
import useStatementGroups from '@/composeables/useStatementGroups';
import ProgressSpinner from '@/App.vue';
import useCurrentUser from '@/composeables/useCurrentUser';
import DetailedObservation from '@/models/DetailedObservation';
import {Teacher} from '@/models/Teacher';
import { fetchPost } from '@/helpers/fetchHelper';
import {Statement} from '@/models/Statement';

export default defineComponent({
    name: 'ObservationAnswerTable',
    components: {ProgressSpinner},
    props: {
        observation: {type: Object as PropType<DetailedObservation>, required: true},
        teacher: {type: Object as PropType<Teacher>, required: true}
    },
    setup(props) {
        useStatementGroups().load();
        let statements: Statement[] = [];
        for (const key in props.observation.statements) {
            props.observation.statements[key].forEach((statement) => {
                statements[statement.orderNumber] = statement;
            });
        }

        statements = statements.filter(Boolean);

        let initialFrom = undefined;
        let initialTo = undefined;

        if (props.observation.developmentZoneFrom !== undefined && props.observation.developmentZoneTo !== undefined) {
            for (const i in statements) {
                if (statements[i].id === props.observation.developmentZoneFrom) {
                    initialFrom = Number(i);
                } else if (statements[i].id === props.observation.developmentZoneTo) {
                    initialTo = Number(i);
                }
            }
        }

        const checkedFrom = ref<number|undefined>(initialFrom);
        const checkedTo = ref<number|undefined>(initialTo);

        return {
            user: useCurrentUser().user,
            statementGroups: useStatementGroups().statementGroups,
            statements,
            checkedTop: checkedFrom,
            checkedBottom: checkedTo,
            onSelect(e: PointerEvent & {target: HTMLInputElement}) {
                const statementIndex = Number(e.target.dataset.statementIndex);

                if (checkedFrom.value !== undefined && checkedFrom.value === statementIndex) {
                    checkedFrom.value = undefined;
                } else if (checkedTo.value !== undefined && checkedTo.value === statementIndex) {
                    checkedTo.value = undefined;
                } else if (checkedFrom.value === undefined || statementIndex < checkedFrom.value) {
                    checkedFrom.value = statementIndex;
                } else {
                    checkedTo.value = statementIndex;
                }

                if ((checkedFrom.value !== undefined && checkedTo.value !== undefined) || (checkedFrom.value === undefined && checkedTo.value === undefined)) {
                    const body = new FormData();
                    body.set('fromId', (checkedFrom.value !== undefined) ? String(statements[checkedFrom.value].id) : '-1');
                    body.set('toId', (checkedTo.value !== undefined) ? String(statements[checkedTo.value].id) : '-1');

                    fetchPost(`wp-json/api/observation/${props.observation.id}/development-zone/`, body);
                }
            }
        }
    }
})
</script>
