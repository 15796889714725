import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "row__header" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "text-primary fw-bold mb-0" }
const _hoisted_5 = { class: "row__tabs row--last mb-4" }
const _hoisted_6 = { class: "nav__tabs" }
const _hoisted_7 = { class: "nav__tabs__tab" }
const _hoisted_8 = { class: "nav__tabs__tab" }
const _hoisted_9 = { class: "nav__tabs__tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.observation)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_RouterLink, {
                to: {name: _ctx.Routes.TeacherDetail, params: {teacherId: _ctx.observation.teacherId}}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("« Terug naar de observaties")
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.observation.name), 1),
            _createElementVNode("small", null, "Observator: " + _toDisplayString(_ctx.observation.authorName), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createVNode(_component_RouterLink, {
                  class: "nav-link",
                  to: {name: _ctx.Routes.ObservationGeneralInfo, params: {observationId: _ctx.observationId}}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Algemene Informatie ")
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("li", _hoisted_8, [
                (_ctx.user?.role ==='observer')
                  ? (_openBlock(), _createBlock(_component_RouterLink, {
                      key: 0,
                      class: "nav-link",
                      to: {name: _ctx.Routes.ObservationForm, params: {observationId: _ctx.observationId, groupId: Object.keys(_ctx.statementGroups)[0] ?? 0}}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Observatieformulier ")
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createVNode(_component_RouterLink, {
                  class: "nav-link",
                  to: {name: _ctx.Routes.ObservationResults, params: {observationId: _ctx.observationId}}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Resultaten ")
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ])
        ]),
        _createVNode(_component_RouterView)
      ], 64))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}