import {z} from 'zod';

export const StatementGroupSchema = z.object({
    id: z.number(),
    name: z.string(),
    color: z.string(),
    order: z.number()
});

export type StatementGroup = z.infer<typeof StatementGroupSchema>;
