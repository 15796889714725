<template>
    <BsCard title="Schoolinformatie" class="w-100 mb-4">
        <div class="mb-3">
            <label class="form-label">
                School

                <select class="form-select" name="school_id" :disabled="assessment?.isClosed">
                    <option value=""/>
                    <option v-for="(label, value) in teacher?.schools ?? {}" :key="`s_${value}`" :value="value" :selected="assessment?.schoolId === Number(value)">
                        {{ label }}
                    </option>
                </select>
            </label>
        </div>
    </BsCard>

    <BsCard title="Gegevens" class="mb-4">
        <div class="d-flex mb-3">
            <div class="me-3">
                <label class="form-label" for="assessment_date">Datum</label>
                <input id="assessment_date" class="form-control" type="date" name="assessment_date" :value="assessment?.getDateISO()" :disabled="assessment?.isClosed">
            </div>

            <div>
                <label class="form-label" for="timeframe_id">Tijd</label>

                <select id="timeframe_id" class="form-select" name="timeframe_id" :disabled="assessment?.isClosed">
                    <option value=""/>
                    <option v-for="(label, value) in info?.timeframes" :key="`t_${value}`" :value="value" :selected="assessment?.timeframeId === Number(value)">
                        {{ label }}
                    </option>
                </select>
            </div>
        </div>

        <div class="d-flex mb-3">
            <div class="me-3">
                <label class="form-label" for="lowest_education_level">Wat is het laagste opleidingsniveau in de klas?</label>

                <select id="lowest_education_level" class="form-select" name="lowest_education_level" :disabled="assessment?.isClosed">
                    <option value=""/>
                    <option v-for="(label, value) in info?.educationLevels" :key="`l_${value}`" :value="value" :selected="assessment?.lowestEducationLevel === value">
                        {{ label }}
                    </option>
                </select>
            </div>


            <div>
                <label class="form-label" for="highest_education_level">Wat is het hoogste opleidingsniveau in de klas?</label>

                <select id="highest_education_level" class="form-select" name="highest_education_level" :disabled="assessment?.isClosed">
                    <option value=""/>
                    <option v-for="(label, value) in info?.educationLevels" :key="`h_${value}`" :value="value" :selected="assessment?.highestEducationLevel === value">
                        {{ label }}
                    </option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">
                Is dit een combinatieklas?<br>

                <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="combined_class" value="1" :checked="assessment?.isCombinedClass === true" :disabled="assessment?.isClosed">
                    Ja
                </label><br>
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="combined_class" value="" :checked="assessment?.isCombinedClass === false" :disabled="assessment?.isClosed">
                    Nee
                </label><br>
            </label>
        </div>

        <div class="mb-3">
            <label class="form-label">
                Geobserveerd vak
                <input class="form-control" type="text" name="observed_school_subject" :value="assessment?.observedSchoolSubject" :disabled="assessment?.isClosed">
            </label>
        </div>

        <div class="mb-3">
            <label class="form-label me-3">
                Klas:
                <input class="form-control" type="text" name="school_class_name" :value="assessment?.schoolClassName" :disabled="assessment?.isClosed">
            </label>

            <label class="form-label me-3">
                Totaal aantal leerlingen
                <input class="form-control" type="number" min="0" name="amount_of_students" :value="assessment?.amountOfStudents" :disabled="assessment?.isClosed">
            </label>

            <label class="form-label mb-3">
                Aantal leerlingen met een LWOO of Rugzakje
                <input class="form-control" type="number" min="0" name="students_with_lwoo_or_backpack" :value="assessment?.amountOfStudentsWithBackpack" :disabled="assessment?.isClosed">
            </label>
        </div>
    </BsCard>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import DetailedPerception from '@/models/DetailedPerception';
import DetailedObservation from '@/models/DetailedObservation';
import BsCard from '@/components/bootstrap/BsCard.vue';
import useSchoolInformation from '@/composeables/useEducationInformation';
import useTeacher from '@/composeables/useTeacher';

export default defineComponent({
    name: 'AssessmentFormFields',
    components: {BsCard},
    props: {
        assessment: {type: Object as PropType<DetailedPerception|DetailedObservation>, required: true}
    },
    setup() {
        useSchoolInformation().load();

        return {
            teacher: useTeacher().teacher,
            info: useSchoolInformation().info
        };
    }
})
</script>
