<template>
    <div class="row__content">
        <div v-if="observations?.length" class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-3 bg-light-blue pt-4">
                    <h6 class="fw-bold">Observaties in de tijd</h6>

                    <p>Lorem teacher ipsum dolor sit amet, consectetur adipiscing elit. Proin sed augue euismod, maximus turpis ut, aliquam nulla. Nunc eu orci porttitor, lacinia lectus nec, feugiat diam. Aliquam erat volutpat.</p>
                </div>

                <div class="col-12 col-md-9 pt-4">
                    <ChartGraph :observations="observations"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-3 bg-light-blue pt-5 pb-3">
                    <h6 class="fw-bold">Voortgang per domein</h6>

                    <p>Lorem teacher ipsum dolor sit amet, consectetur adipiscing elit. Proin sed augue euismod, maximus turpis ut, aliquam nulla. Nunc eu orci porttitor, lacinia lectus nec, feugiat diam. Aliquam erat volutpat.</p>
                </div>

                <div class="col-12 col-md-9 pt-5 pb-3">
                    <template v-if="statementGroups">
                        <div v-for="(group, index) in statementGroups" :key="group.id" class="mb-5">
                            <h3 class="head__label" :class="`group__item${index}`">{{ group.name }}</h3>
                            <ChartGraph :observations="observations" :group-id="group.id" :color="group.color"/>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-else-if="observations" class="row__notification">
            Deze persoon heeft nog geen observaties.
        </div>
        <ProgressSpinner v-else/>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useObservations from '@/composeables/useObservations';
import useTeacher from '@/composeables/useTeacher';
import useStatementGroups from '@/composeables/useStatementGroups';
import ChartGraph from '@/components/charts/ChartGraph.vue';
import ProgressSpinner from '@/App.vue';

export default defineComponent({
    name: 'ObservationResults',
    components: {ProgressSpinner, ChartGraph},
    props: {
        teacherId: {type: Number, required: true}
    },
    setup(props) {
        useStatementGroups().load();
        useObservations().load(props.teacherId);
        useTeacher().load(props.teacherId);

        return {
            teacher: useTeacher().teacher,
            observations: useObservations().observations,
            statementGroups: useStatementGroups().sortedGroups
        }
    }
})
</script>
