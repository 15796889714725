import {z} from 'zod';

const IndicatorSchema = z.object({
    answer: z.string(),
    uid: z.string()
});

export const StatementSchema = z.object({
    id: z.number().readonly(),
    groupId: z.number().readonly(),
    question: z.string().readonly(),
    orderNumber: z.number().readonly(),
    orderInGroup: z.number().readonly(),
    questionNumber: z.number().optional().readonly(),
    indicatorsAmount: z.number().readonly(),
    indicators: z.array(IndicatorSchema).readonly(),
    selectedIndications: z.array(z.string()).optional(),
    score: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]).optional()
});

export type Statement = z.infer<typeof StatementSchema>;
