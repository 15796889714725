import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row__content row--radius py-3" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-4 px-0 border-end" }
const _hoisted_5 = { class: "list-unstyled observation-form__tabs" }
const _hoisted_6 = { class: "group__item__name" }
const _hoisted_7 = {
  key: 0,
  class: "group__item__counter group__item__counter--success"
}
const _hoisted_8 = {
  key: 1,
  class: "group__item__counter"
}
const _hoisted_9 = { class: "col-12 col-md-8 px-0" }
const _hoisted_10 = {
  key: 0,
  class: "statements"
}
const _hoisted_11 = { class: "statements__item__indicators" }
const _hoisted_12 = { class: "fw-bold mb-3" }
const _hoisted_13 = { class: "statements__item__score" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_IndicationSelect = _resolveComponent("IndicationSelect")!
  const _component_StatementSelect = _resolveComponent("StatementSelect")!

  return (_ctx.statementGroups)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["layout__wrapper", {'observation-closed': _ctx.observation?.isClosed}])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statementGroups, (group, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: group.id
                    }, [
                      _createVNode(_component_RouterLink, {
                        to: {name: _ctx.Routes.ObservationForm, params: {observationId: _ctx.observationId, groupId: group.id}},
                        class: _normalizeClass(["group__item", `group__item${index}`])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(group.name), 1),
                          _createTextVNode("  "),
                          (_ctx.observation && _ctx.isGroupComplete(_ctx.observation, group.id))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, " ✔ "))
                            : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.unansweredGroupQuestions(_ctx.observation, group.id)), 1))
                        ]),
                        _: 2
                      }, 1032, ["to", "class"])
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.observation?.statements[_ctx.groupId])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.observation.getSortedStatements(_ctx.groupId), (statement) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: statement.id,
                          class: "statements__item"
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("h6", _hoisted_12, _toDisplayString(statement.question), 1),
                            _createVNode(_component_IndicationSelect, {
                              statement: statement,
                              "observation-id": _ctx.observationId,
                              disabled: _ctx.observation?.isClosed
                            }, null, 8, ["statement", "observation-id", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_StatementSelect, {
                              statement: statement,
                              "observation-id": _ctx.observationId,
                              disabled: _ctx.observation?.isClosed
                            }, null, 8, ["statement", "observation-id", "disabled"])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}